import React from "react";
import { NoteWrapperContext } from "./NoteWrapper";

const Note = ({ indicator, content, children }) => {
  const noteRef = React.useRef(null);
  const noteContext = React.useContext(NoteWrapperContext);

  return (
    <span ref={noteRef}>
      {content && (
        <span className="note__content">
          {content}
          <button
            className="note__content__back"
            onClick={() => noteContext.hide(noteRef.current)}
          >
            ❮ Back
          </button>
        </span>
      )}
      {children && (
        <span
          className="note__reference"
          tabIndex={0}
          role="button"
          onClick={() => content && noteContext.show(noteRef.current)}
        >
          {children}
        </span>
      )}
      {indicator && <span className="note__indicator">{indicator}</span>}
    </span>
  );
};

export default Note;
