import React from "react";
import Note from "./Note";

import "./regularNote.scss";

const RegularNote = ({ content, children }) => {
  return (
    <Note
      content={
        content && (
          <>
            {content}
            <span className="regular-note__icon">✱</span>
          </>
        )
      }
    >
      {children}
    </Note>
  );
};

export default RegularNote;
